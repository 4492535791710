@import "mixin";
@import "animate";

$offcanvas-width: 260px !default;
$color-base: #333;
$color-red: #E00012;
$color-yellow: #FFDD28;

@charset "UTF-8";
@include keyframes(animationOpacity){
	0% {
		opacity:0;
	}
	30% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
body{
	@include animation(animationOpacity 1.2s ease 0.15s);
}
body{
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif;
  a{
    color: $color-base;
  }
  a:hover{
    color: $color-base;
  }
  a.disable {
    pointer-events: none;
    cursor: default;
  }
}
.nav-sp{
	@include mq('min', 'lg'){
		background: #000;
		height:70px;
		position: fixed;
		top:0;
		left: 0;
		width: 100vw;
		z-index: 9999;
		.logo{
			width: 210px;
			top:10px;
			left:10px;
			position: fixed;
			z-index: 1;
			a{
				display: block;
				padding: 10px;
			}
		}
	  .menu-trigger {
	    display: none;
	  }
	  ul {
	    position: fixed;
	    top: 10px;
	    right: 10px;
	    z-index: 1005;
	    margin: 0 0 5px;
	    li {
	      color: #ccc;
				display: inline-block;
	      a {
	        color: #ccc;
	        display: inline-block;
	        text-decoration: none;
		      padding: .8rem .5rem;
					font-size: .9rem;
					&:hover{
						color: #999;
		      }
	      }
				&.lang{
					a{
						padding: 0;
						margin:.8rem .5rem;
						// &:hover{
						// 	border-bottom: 1px solid #999;
						// }
						&.current{
							border-bottom: 1px solid #ccc;
						}
					}
				}
	    }
	  }
	}
	@include mq('max', 'lg') {
		.logo{
			display: none;
		}
	  .menu-trigger {
	    display: inline-block;
	    width: 18px;
	    height: 14px;
	    vertical-align: middle;
	    cursor: pointer;
	    position: fixed;
	    top: 15px;
	    left: 20px;
	    z-index: 1002;
	    transform: translateX(0);
	    span {
	      display: inline-block;
	      box-sizing: border-box;
	      position: absolute;
	      left: 0;
	      width: 100%;
	      height: 2px;
	      background-color: #ccc;
	      transition: all .5s;
	      &:nth-of-type(1) {
	        top: 0;
	      }
	      &:nth-of-type(2) {
	        top: 6px;
	      }
	      &:nth-of-type(3) {
	        bottom: 0;
	      }
	    }
	    &.active {
	      transform: translateX(0);
	      z-index: 2000;
	      span {
	        background-color: #707070 !important;
	        &:nth-of-type(1) {
	          transform: translateY(6px) rotate(-45deg);
	        }
	        &:nth-of-type(2) {
	          opacity: 0;
	        }
	        &:nth-of-type(3) {
	          transform: translateY(-6px) rotate(45deg);
	        }
	      }
	    }
	  }
	  ul {
	    width: 260px;
	    height: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1005;
	    transform: translate(-260px);
	    transition: all .5s;
	    overflow-x:hidden !important;
			opacity: 0;
	    margin: 0 0 5px;
	    padding-top: 50px;
	    &.open {
		    background: #000;
	      transform: translateX(0);
	      overflow:hidden;
	      box-sizing:border-box;
				opacity: 1;

		    li {
					opacity: 1;
				}
	    }
	    li {
	      color: #ccc;
				opacity: 0;
	      a {
	        color: #ccc;
	        display: block;
	        text-decoration: none;
		      padding: 9px 10px 9px 20px;
					&:hover{
						color: #fff;
		      }
	      }
				&.lang{
					display: flex;
					a{
						padding: 2px;
						margin: 7px 8px 7px 18px;
						// &:hover{
						// 	border-bottom: 1px solid #ccc;
						// }
					}
					a+a{
						margin-left: 8px;
					}
					.current{
						border-bottom: 1px solid #ccc;
					}
				}
	    }
	  }
	  .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background-color: rgba(0, 0, 0, 0.5);
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1001;
	    opacity: 0;
	    transition: opacity .5s;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
	    }
	  }
	}
	&.colorChange{
		@include mq('min', 'lg'){
			ul li a{
				color: $color-base;
				&:hover{
					color: #666;
				}
			}
			ul li.lang{
				 .current{//, a:hover{
					border-bottom-color: $color-base;
				}
			}
		}
		@include mq('max', 'lg'){
			.menu-trigger span{
				background-color: $color-base;
			}
		}
	}
}

footer{
  .copyright{
    font-size: .625rem;
    text-align: center;
    margin-top: 3rem;
    color: #999;
  }
}
.to-page-top {
	@include mq('min', 'xs') {
	  z-index: 1100;
	  position: fixed;
	  right: 15px;
	  bottom: 15px;
	  display: none;
	  a {
	    text-decoration: none;
	    display: block;
	    text-align: center;
	    background: rgba(255,255,255,.95);
			padding: 6px;
			box-shadow: 0 1px 5px rgba(0,0,0,.1);
			width: 40px;
			height: 40px;
			border-radius: 50%;
			color: $color-base;
	  }
	}
	@include mq('max', 'xs') {
		display: none !important;
	}
}

#localnav ul{
  padding-left: 0;
  margin-bottom: 0;
  li{
    font-size: .9rem;
    list-style: none;
    a{
      display: block;
      text-align: center;
      border:1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
#contents{
	@include mq('min', 'lg') {
		padding-top: 100px;
	}
  #breadcrumb{
    font-size: 12px;
    padding:10px 0 20px;
    @include mq('max', 'lg') {
      padding-bottom:40px;
    }
  }
  #headline{
		display: none;
    background: #111;
    h1{
      font-size: 1.6rem;
      color: #ccc;
      vertical-align: middle;
			line-height: 1.6;
    }
    @include mq('max', 'lg') {
	    height: 140px;
	    h1{
	      padding-top: 50px;
	    }
		}
    @include mq('min', 'lg') {
	    height: 210px;
	    h1{
	      padding-top: 95px;
	    }
		}
  }
  @include mq('max', 'lg') {
    #breadcrumb{
      padding-bottom:40px;
    }
  }
  h2{
    font-size: 1.5rem;
		line-height: 1.6;
		font-weight: bold;
		@include mq('max', 'sm') {
	    font-size: 1.3rem;
			font-feature-settings : "palt";
		}
  }
  h3{
    line-height: 1.5;
		font-weight: bold;
		font-size: 1.375rem;
		@include mq('max', 'sm') {
	    font-size: 1.1rem;
			font-feature-settings : "palt";
		}
  }
  p{
    line-height: 1.8;
  }
	dt, dd, th, td{
    line-height: 1.6;
  }
	dt{
		font-size: 1.1rem;
	}
	dd{
		margin-bottom: .4rem;
	}
  li{
    font-size: .9rem;
    line-height: 1.8;
  }
	a{
		text-decoration: underline;
	}
  hr{
    border-bottom:3px solid $color-base;
    margin-bottom: 0;
  }
	.profile{
    h2{
      span{
        font-size: 60%;
      }
    }
	}
	.slider{
		img{
			display: block;
		}
		.slick-dots{
			bottom:-20px;
			li{
				height: 10px;
				width: 10px;
				margin:0 3px;
				button{
					height: 10px;
					width: 10px;
					padding: 0;
					&::before{
						width: 10px;
						height: 10px;
						line-height: 10px;
					}
				}
			}
		}
	}
}
.en{
	#contents{
    h1{
			line-height: 1.4;
    }
	  h2{
			line-height: 1.4;
	  }
	  h3{
	    line-height: 1.4;
		}
	  p{
	    line-height: 1.8;
	  }
		dt, dd, th, td{
	    line-height: 1.4;
	  }
	}
}

.home{
	.nav-sp{
		@include mq('max', 'lg') {
		  .menu-trigger {
		    span {
		      background-color:$color-base;
				}
			}
		}
		@include mq('min', 'lg') {
			background: #fff;
			// height: auto;
			.logo{
				display: none;
			}
			li a{
				color: $color-base;
			}
			ul li.lang{
				a.current{
					border-bottom-color: $color-base;
				}
				// a:hover{
				// 	border-bottom-color: #ccc;
				// }
			}
		}
	}
	@include mq('min', 'lg') {
		#contents{
			padding-top: 0;
		}
	}
	#feature img{
		padding:80px 0 40px;
	}
  #main{
    .section{
      p{
        margin-bottom: 0;
      }
    }
    #langage{
      a{
        padding:24px 10px;
        display: inline-block;
        font-weight: bold;
        font-size: 1.1rem;
				text-decoration: none;
      }
			@media screen and (min-width: 992px){
				display: none;
			}
    }
    #menu{
      li{
        margin-bottom: 15px;
        font-size: 1.1rem;
				font-weight: bold;
        a{
	        border:1px solid #444;
          padding: 1rem 10px;
          display: block;
          text-decoration: none;
          transition: .2s;
          &:hover{
            background: #444;
            color: #fff;
          }
        }
      }
			@media screen and (min-width: 992px){
				ul{
					display: flex;
					justify-content:space-between;
					li{
						width: 19%;
						a{
							font-size: 1rem;
						}
					}
				}
			}
    }
  }
	#instagram{
		h2{
			i{
				font-size: 28px;
			}
			margin-bottom: 1.4rem;
		}
	}
}
.about-top{
	#main{
		h2{
			line-height: 1.6;
			span{
				font-size: 1.1rem;
				@include mq('max', 'sm') {
			    font-size: .9rem;
				}
			}
		}
		#youtube {
		  position: relative;
		  width: 100%;
		  padding-top: 56.25%;
		}
		#youtube iframe {
		  position: absolute;
		  top: 0;
		  right: 0;
		  width: 100% !important;
		  height: 100% !important;
		}
		#activity{
			th, td{
				@include mq('max', 'sm') {
					font-size: .9rem;
				}
			}
		}
	}
}
.member{
  #main{
		.profile-photo2{
			display: none;
		}
    .company{
      a{
        display: block;
        background: #ccc;
				padding: .6rem .75rem;
				font-weight: bold;
        transition: .3s;
        border-radius: 10px;
				text-decoration: none;
        &:hover{
          background: #666;
          color: #fff;
        }
      }
    }
  }
}
.member2{
  #main{
		.profile-photo1{
			display: none;
		}
		.profile-photo2{
			display: block;
		}
	}
}
.company{
	#contents{
		#speciality,#products{
			h2{
				@include mq('max', 'xs') {
					font-size: 1.2rem;
				}
			}
		}
		#products{
			h3{
				font-size: 1.2rem;
				line-height: 1.6;
			}
		}
	}
	.profile-photo2{
		display: none;
	}
}
.contact{
	form{
		table{
			width: 100%;
			th, td{
				display: block;
				width:100%;
			}
			th{
				span.required{
					color: #F33;
					font-size: .7rem;
					font-weight: normal;
					margin-left: .4rem;
				}
			}
			td{
				margin-bottom: 1rem;
				input[type="text"]{
					max-width:100% !important;
				}
				textarea{
					width:100% !important;
				}
			}
		}
		.btn{
			width: 100%;
			box-shadow: 0 2px 5px rgba(0,0,0,0.2);
			background: $color-base;
			color: #eee;
			&:hover{
				background: #ccc;
				color: $color-base;
				box-shadow: 0 1px 1px rgba(0,0,0,0.1);
			}
		}
		dl{
			width: 100%;
			dt{
				font-size: 1rem !important;
				margin-bottom: 2px;
				padding-left: 3px;
				span.required{
					color: #F33;
					font-size: .7rem;
					font-weight: normal;
					margin-left: .4rem;
				}
			}
			dd{
				margin-bottom: 1rem;
				padding-left: 3px;
				input[type="text"], input[type="tel"], input[type="email"], label, textarea{
				  box-sizing: border-box;
					width: 100%;
				}
				select{
					height: 1.9rem;
				}
				label{
					display: block;
					margin-bottom:0;
				}
				.error_blank{
					display: block;
					padding:3px 0 10px;
					color: #F33;
					font-size: .9rem;
				}
				@include mq('min', 'lg') {
					input[type="text"], input[type="tel"], input[type="email"]{
						width: 50%;
					}
				}
			}
		}
	}
}

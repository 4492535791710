@charset "UTF-8";
@keyframes animationOpacity {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

body {
  animation: animationOpacity 1.2s ease 0.15s;
  animation-fill-mode: both; }

body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif; }
  body a {
    color: #333; }
  body a:hover {
    color: #333; }
  body a.disable {
    pointer-events: none;
    cursor: default; }

@media screen and (min-width: 62.0625em) {
  .nav-sp {
    background: #000;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9999; }
    .nav-sp .logo {
      width: 210px;
      top: 10px;
      left: 10px;
      position: fixed;
      z-index: 1; }
      .nav-sp .logo a {
        display: block;
        padding: 10px; }
    .nav-sp .menu-trigger {
      display: none; }
    .nav-sp ul {
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: 1005;
      margin: 0 0 5px; }
      .nav-sp ul li {
        color: #ccc;
        display: inline-block; }
        .nav-sp ul li a {
          color: #ccc;
          display: inline-block;
          text-decoration: none;
          padding: .8rem .5rem;
          font-size: .9rem; }
          .nav-sp ul li a:hover {
            color: #999; }
        .nav-sp ul li.lang a {
          padding: 0;
          margin: .8rem .5rem; }
          .nav-sp ul li.lang a.current {
            border-bottom: 1px solid #ccc; } }

@media screen and (max-width: 62em) {
  .nav-sp .logo {
    display: none; }
  .nav-sp .menu-trigger {
    display: inline-block;
    width: 18px;
    height: 14px;
    vertical-align: middle;
    cursor: pointer;
    position: fixed;
    top: 15px;
    left: 20px;
    z-index: 1002;
    transform: translateX(0); }
    .nav-sp .menu-trigger span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ccc;
      transition: all .5s; }
      .nav-sp .menu-trigger span:nth-of-type(1) {
        top: 0; }
      .nav-sp .menu-trigger span:nth-of-type(2) {
        top: 6px; }
      .nav-sp .menu-trigger span:nth-of-type(3) {
        bottom: 0; }
    .nav-sp .menu-trigger.active {
      transform: translateX(0);
      z-index: 2000; }
      .nav-sp .menu-trigger.active span {
        background-color: #707070 !important; }
        .nav-sp .menu-trigger.active span:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg); }
        .nav-sp .menu-trigger.active span:nth-of-type(2) {
          opacity: 0; }
        .nav-sp .menu-trigger.active span:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg); }
  .nav-sp ul {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1005;
    transform: translate(-260px);
    transition: all .5s;
    overflow-x: hidden !important;
    opacity: 0;
    margin: 0 0 5px;
    padding-top: 50px; }
    .nav-sp ul.open {
      background: #000;
      transform: translateX(0);
      overflow: hidden;
      box-sizing: border-box;
      opacity: 1; }
      .nav-sp ul.open li {
        opacity: 1; }
    .nav-sp ul li {
      color: #ccc;
      opacity: 0; }
      .nav-sp ul li a {
        color: #ccc;
        display: block;
        text-decoration: none;
        padding: 9px 10px 9px 20px; }
        .nav-sp ul li a:hover {
          color: #fff; }
      .nav-sp ul li.lang {
        display: flex; }
        .nav-sp ul li.lang a {
          padding: 2px;
          margin: 7px 8px 7px 18px; }
        .nav-sp ul li.lang a + a {
          margin-left: 8px; }
        .nav-sp ul li.lang .current {
          border-bottom: 1px solid #ccc; }
  .nav-sp .overlay {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    opacity: 0;
    transition: opacity .5s; }
    .nav-sp .overlay.open {
      width: 100%;
      height: 100%;
      opacity: 1; } }

@media screen and (min-width: 62.0625em) {
  .nav-sp.colorChange ul li a {
    color: #333; }
    .nav-sp.colorChange ul li a:hover {
      color: #666; }
  .nav-sp.colorChange ul li.lang .current {
    border-bottom-color: #333; } }

@media screen and (max-width: 62em) {
  .nav-sp.colorChange .menu-trigger span {
    background-color: #333; } }

footer .copyright {
  font-size: .625rem;
  text-align: center;
  margin-top: 3rem;
  color: #999; }

@media screen and (min-width: 30.125em) {
  .to-page-top {
    z-index: 1100;
    position: fixed;
    right: 15px;
    bottom: 15px;
    display: none; }
    .to-page-top a {
      text-decoration: none;
      display: block;
      text-align: center;
      background: rgba(255, 255, 255, 0.95);
      padding: 6px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #333; } }

@media screen and (max-width: 30.0625em) {
  .to-page-top {
    display: none !important; } }

#localnav ul {
  padding-left: 0;
  margin-bottom: 0; }
  #localnav ul li {
    font-size: .9rem;
    list-style: none; }
    #localnav ul li a {
      display: block;
      text-align: center;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px; }
      #localnav ul li a:hover {
        text-decoration: none; }

@media screen and (min-width: 62.0625em) {
  #contents {
    padding-top: 100px; } }

#contents #breadcrumb {
  font-size: 12px;
  padding: 10px 0 20px; }
  @media screen and (max-width: 62em) {
    #contents #breadcrumb {
      padding-bottom: 40px; } }

#contents #headline {
  display: none;
  background: #111; }
  #contents #headline h1 {
    font-size: 1.6rem;
    color: #ccc;
    vertical-align: middle;
    line-height: 1.6; }
  @media screen and (max-width: 62em) {
    #contents #headline {
      height: 140px; }
      #contents #headline h1 {
        padding-top: 50px; } }
  @media screen and (min-width: 62.0625em) {
    #contents #headline {
      height: 210px; }
      #contents #headline h1 {
        padding-top: 95px; } }

@media screen and (max-width: 62em) {
  #contents #breadcrumb {
    padding-bottom: 40px; } }

#contents h2 {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: bold; }
  @media screen and (max-width: 36em) {
    #contents h2 {
      font-size: 1.3rem;
      font-feature-settings: "palt"; } }

#contents h3 {
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.375rem; }
  @media screen and (max-width: 36em) {
    #contents h3 {
      font-size: 1.1rem;
      font-feature-settings: "palt"; } }

#contents p {
  line-height: 1.8; }

#contents dt, #contents dd, #contents th, #contents td {
  line-height: 1.6; }

#contents dt {
  font-size: 1.1rem; }

#contents dd {
  margin-bottom: .4rem; }

#contents li {
  font-size: .9rem;
  line-height: 1.8; }

#contents a {
  text-decoration: underline; }

#contents hr {
  border-bottom: 3px solid #333;
  margin-bottom: 0; }

#contents .profile h2 span {
  font-size: 60%; }

#contents .slider img {
  display: block; }

#contents .slider .slick-dots {
  bottom: -20px; }
  #contents .slider .slick-dots li {
    height: 10px;
    width: 10px;
    margin: 0 3px; }
    #contents .slider .slick-dots li button {
      height: 10px;
      width: 10px;
      padding: 0; }
      #contents .slider .slick-dots li button::before {
        width: 10px;
        height: 10px;
        line-height: 10px; }

.en #contents h1 {
  line-height: 1.4; }

.en #contents h2 {
  line-height: 1.4; }

.en #contents h3 {
  line-height: 1.4; }

.en #contents p {
  line-height: 1.8; }

.en #contents dt, .en #contents dd, .en #contents th, .en #contents td {
  line-height: 1.4; }

@media screen and (max-width: 62em) {
  .home .nav-sp .menu-trigger span {
    background-color: #333; } }

@media screen and (min-width: 62.0625em) {
  .home .nav-sp {
    background: #fff; }
    .home .nav-sp .logo {
      display: none; }
    .home .nav-sp li a {
      color: #333; }
    .home .nav-sp ul li.lang a.current {
      border-bottom-color: #333; } }

@media screen and (min-width: 62.0625em) {
  .home #contents {
    padding-top: 0; } }

.home #feature img {
  padding: 80px 0 40px; }

.home #main .section p {
  margin-bottom: 0; }

.home #main #langage a {
  padding: 24px 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none; }

@media screen and (min-width: 992px) {
  .home #main #langage {
    display: none; } }

.home #main #menu li {
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: bold; }
  .home #main #menu li a {
    border: 1px solid #444;
    padding: 1rem 10px;
    display: block;
    text-decoration: none;
    transition: .2s; }
    .home #main #menu li a:hover {
      background: #444;
      color: #fff; }

@media screen and (min-width: 992px) {
  .home #main #menu ul {
    display: flex;
    justify-content: space-between; }
    .home #main #menu ul li {
      width: 19%; }
      .home #main #menu ul li a {
        font-size: 1rem; } }

.home #instagram h2 {
  margin-bottom: 1.4rem; }
  .home #instagram h2 i {
    font-size: 28px; }

.about-top #main h2 {
  line-height: 1.6; }
  .about-top #main h2 span {
    font-size: 1.1rem; }
    @media screen and (max-width: 36em) {
      .about-top #main h2 span {
        font-size: .9rem; } }

.about-top #main #youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%; }

.about-top #main #youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important; }

@media screen and (max-width: 36em) {
  .about-top #main #activity th, .about-top #main #activity td {
    font-size: .9rem; } }

.member #main .profile-photo2 {
  display: none; }

.member #main .company a {
  display: block;
  background: #ccc;
  padding: .6rem .75rem;
  font-weight: bold;
  transition: .3s;
  border-radius: 10px;
  text-decoration: none; }
  .member #main .company a:hover {
    background: #666;
    color: #fff; }

.member2 #main .profile-photo1 {
  display: none; }

.member2 #main .profile-photo2 {
  display: block; }

@media screen and (max-width: 30.0625em) {
  .company #contents #speciality h2, .company #contents #products h2 {
    font-size: 1.2rem; } }

.company #contents #products h3 {
  font-size: 1.2rem;
  line-height: 1.6; }

.company .profile-photo2 {
  display: none; }

.contact form table {
  width: 100%; }
  .contact form table th, .contact form table td {
    display: block;
    width: 100%; }
  .contact form table th span.required {
    color: #F33;
    font-size: .7rem;
    font-weight: normal;
    margin-left: .4rem; }
  .contact form table td {
    margin-bottom: 1rem; }
    .contact form table td input[type="text"] {
      max-width: 100% !important; }
    .contact form table td textarea {
      width: 100% !important; }

.contact form .btn {
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background: #333;
  color: #eee; }
  .contact form .btn:hover {
    background: #ccc;
    color: #333;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.contact form dl {
  width: 100%; }
  .contact form dl dt {
    font-size: 1rem !important;
    margin-bottom: 2px;
    padding-left: 3px; }
    .contact form dl dt span.required {
      color: #F33;
      font-size: .7rem;
      font-weight: normal;
      margin-left: .4rem; }
  .contact form dl dd {
    margin-bottom: 1rem;
    padding-left: 3px; }
    .contact form dl dd input[type="text"], .contact form dl dd input[type="tel"], .contact form dl dd input[type="email"], .contact form dl dd label, .contact form dl dd textarea {
      box-sizing: border-box;
      width: 100%; }
    .contact form dl dd select {
      height: 1.9rem; }
    .contact form dl dd label {
      display: block;
      margin-bottom: 0; }
    .contact form dl dd .error_blank {
      display: block;
      padding: 3px 0 10px;
      color: #F33;
      font-size: .9rem; }
    @media screen and (min-width: 62.0625em) {
      .contact form dl dd input[type="text"], .contact form dl dd input[type="tel"], .contact form dl dd input[type="email"] {
        width: 50%; } }
